export enum PaymentMethodEnum {
    AFT = 'akhtar_fuiou_technologies',
    AL_BARAKA = 'al_baraka_islamic_bank',
    AL_MEEZAN = 'al_meezan_investment_management',
    ALLIED_BANK = 'allied_bank',
    APNA_MICROFINANCE = 'apna_microfinance_bank',
    ASKARI_BANK = 'askari_commercial_bank',
    BANK_AL_HABIB = 'bank_al_habib',
    BANK_ALFALAH = 'bank_alfalah',
    BANK_ISLAMI = 'bankislami_pakistan',
    BANK_KHYBER = 'bank_of_khyber',
    BANK_MAKRAMAH = 'bank_makramah',
    BANK_PUNJAB = 'bank_of_punjab',
    BANKTRANSFER = 'banktransfer',
    BURJ_BANK = 'burj_bank',
    CASH = 'cash',
    CDNS = 'cdns',
    CHEQUE = 'cheque',
    CITI_BANK = 'citi_bank',
    DUBAI_ISLAMIC = 'dubai_islamic_bank_pakistan',
    EASYPAISA = 'easypaisa',
    EASYPAY_TELENOR = 'easypay_telenor_bank',
    FAYSALBANK = 'faysalbank',
    FINCA = 'finca_microfinance_bank',
    FINJA = 'finja',
    FINJA_EMI = 'finja_emi',
    FIRST_WOMEN_BANK = 'first_women_bank',
    FIRSTPAY_HBL = 'firstpay_hbl_mfb',
    HABIB_METRO = 'habib_metropolitan_bank',
    HBL_KONNECT = 'hbl_konnect',
    ICBC = 'icbc',
    JAZZCASH = 'jazzcash',
    JS_BANK = 'js_bank',
    KASB_BANK = 'kasb_bank',
    KEENU = 'keenu',
    KHUSHHALI_BANK = 'khushhali_microfinance_bank',
    MCB_BANK = 'mcb_bank',
    MCB_FUNDS = 'mcb_funds',
    MCB_ISLAMIC = 'mcb_islamic',
    MEEZANBANK = 'meezanbank',
    MOBILINK_BANK = 'mobilink_microfinance_jazzcash',
    NAYAPAY = 'nayapay',
    NBP = 'national_bank_of_pakistan',
    NBP_FUNDS = 'nbp_fund_management',
    NRSP_MFBL = 'nrsp_mfbl',
    ONEZAPP = 'onezapp',
    PAYMAX = 'paymax',
    SADAPAY = 'sadapay',
    SAMBA = 'samba',
    SILK_BANK = 'silk_bank',
    SINDH_BANK = 'sindh_bank',
    SONERI_BANK = 'soneri_bank',
    STANDARD_CHARTERED = 'standard_chartered_bank',
    SUMMIT_BANK = 'summit_bank',
    UBANK_UPAISA = 'ubank_upaisa',
    UBL = 'united_bank_limited',
    UNKNOWN_PM_FROM_CHATBOT = 'unknown_pm_from_chatbot',
    ZTBL = 'zarai_taraqiati_bank',
}
