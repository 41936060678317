import React, { useEffect, useState } from 'react';
import { Modal, message, Button, Input, Form, Select, Tag } from 'antd';
import { useMutation, useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { IItemsWithCount, IDocumentEntity, KeyValueRecord, DocumentTypeSearch, getEnumeratorKeys, ITransactionVerificationDTO, PaymentMethodEnum, ITransactionVerificationEntity, removeWhiteSpaceFromAllProperties, removeEmptyValues } from '@rasayi-workspace/shared';
import { GetItems, PutItem, } from '@services';
import { BASE_QUERY_OPTIONS } from '@constants';
import { AddEditModalProps, ErrorResponse } from '@interfaces';
import { Image } from 'antd';
import { CloudDownloadOutlined, FileOutlined } from '@ant-design/icons';
import { handleDownloadImage } from '@helpers';

export const EditDocumentModalComponent = ({ onClose, onSubmit, editMode = false, id: paymentProofId = '' }: AddEditModalProps<IDocumentEntity>) => {
    const [form] = Form.useForm<Partial<ITransactionVerificationDTO>>();
    const [messageApi, contextHolder] = message.useMessage();
    const [paymentProofDocument, setPaymentProofDocument] = useState<any>([]);

    const { refetch: paymentProofDocumentsOne, isFetching: isDocumentsFetching } = useQuery<IItemsWithCount<any>, AxiosError>({
        ...BASE_QUERY_OPTIONS,
        queryKey: ['getPaymentProofDocument'],
        queryFn: () => {
            const queryParams = new URLSearchParams({
                paymentProofId: paymentProofId
            });

            return GetItems<KeyValueRecord>('transaction_verification/payment_proof?' + queryParams.toString());
        },
        onSuccess: (returnedResult: IItemsWithCount<KeyValueRecord>): void => {
            setPaymentProofDocument(returnedResult || []);
        },
        onError: () => setPaymentProofDocument([]),
    });

    const { mutate: updateTransactionVerification, isLoading: isVendorUpdating } = useMutation<ITransactionVerificationEntity, AxiosError>({
        mutationKey: ['transaction_verification'],
        mutationFn: async () =>
            PutItem<ITransactionVerificationEntity, ITransactionVerificationDTO>(
                'transaction_verification/manual',
                paymentProofId,
                removeWhiteSpaceFromAllProperties(removeEmptyValues(form.getFieldsValue())) as Partial<ITransactionVerificationDTO>
            ),
        onSuccess: async (item: any): Promise<void> => {
            onSubmit(item),
                onClose()
        },
        onError: (error: AxiosError) => {
            messageApi.open({
                type: 'error',
                content: (error?.response?.data as ErrorResponse)?.message || 'Contact support for details'
            });
        }
    });

    const updateFormFields = (property: string, value: string | number | undefined) => form.setFieldsValue({ [property]: value });

    const onOkEditButtonClickHandler = async () => await form.validateFields()
        .then(() => updateTransactionVerification())

    const handleFormChange = () => {
        const fieldsTouched = form.isFieldsTouched(true);
        const hasErrors = form.getFieldsError().filter(({ errors }) => errors.length)
            .length > 0;
    }


    useEffect(() => {
        if (paymentProofDocument) {
            form.setFieldsValue({
                transaction_id: paymentProofDocument?.[0]?.transaction_id || '',
                payment_method: paymentProofDocument?.[0]?.payment_method || '',
                amount: paymentProofDocument?.[0]?.amount || '',
                verified: paymentProofDocument?.[0]?.verified || false,
            });
        }
    }, [paymentProofDocument])

    useEffect(() => {
        !!paymentProofId && !isDocumentsFetching && paymentProofDocumentsOne();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const options = [
        {
            value: true,
            label: 'Yes',
        },
        {
            value: false,
            label: 'No'
        }
    ];

    return (
        <>
            {contextHolder}
            <Modal
                title={'Transaction Verification'}
                open={true}
                confirmLoading={isDocumentsFetching}
                onOk={() => onOkEditButtonClickHandler()}
                onCancel={() => onClose()}
                maskClosable={false}
                bodyStyle={{ maxHeight: 550, overflow: 'auto' }}
            >
                <h2>
                    Documents
                </h2>
                {paymentProofDocument && !isDocumentsFetching && <Form
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    form={form}
                    onValuesChange={handleFormChange}
                >
                    <Form.Item
                        name='transaction_id'
                        label='Transaction ID'
                    >
                        <Input onChange={(event) => updateFormFields('transaction_id', event?.target.value)} />
                    </Form.Item>


                    <Form.Item
                        name='payment_method'
                        label='Payment Method'
                    >
                        <Select onChange={(value) => updateFormFields('payment_method', value)}>
                            {
                                getEnumeratorKeys(PaymentMethodEnum).map(
                                    (key) => <Select.Option key={key}
                                        value={PaymentMethodEnum[key as keyof typeof PaymentMethodEnum]}>
                                        <Tag key={key}>{key.toUpperCase()}</Tag>
                                    </Select.Option>
                                )
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name='amount'
                        label='Amount'
                    >
                        <Input
                            type='number'
                            className="[appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                            onChange={(event) => updateFormFields('amount', event?.target.value ? parseInt(event?.target.value) : undefined)} />
                    </Form.Item>
                    <Form.Item name='verified'
                        label='Is verified'
                        rules={[{ required: false }]}>
                        <Select>
                            {options.map((o, i) => {
                                return (
                                    <Select.Option key={`option-${i}`} value={o.value}>
                                        {o.label}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    </Form.Item>
                </Form>}

                {
                    paymentProofDocument &&
                    paymentProofDocument?.map((image: KeyValueRecord, index: number) => {
                        return <div className='flex-container'
                            style={{ marginTop: 20, display: 'flex', alignItems: 'center' }}>

                            {!image?.isPdf ?
                                <div>
                                    <h4 key={image.id}>
                                        {Object.values(DocumentTypeSearch).find(el => image?.payment_proof?.includes(el))?.toUpperCase()}
                                    </h4>
                                    <Image
                                        key={index}
                                        width={400}
                                        src={image?.fileContent}
                                    />
                                </div>
                                :
                                <div>
                                    <h4 key={image.id}>
                                        {Object.values(DocumentTypeSearch).find(el => image?.payment_proof?.includes(el))?.toUpperCase()}
                                    </h4>
                                    <div className='w-[400px] flex justify-center'><FileOutlined /></div>
                                </div>
                            }
                            <div>
                                <Button
                                    className=' '
                                    type='primary'
                                    icon={<CloudDownloadOutlined />}
                                    style={{ marginLeft: 10, marginBottom: 5 }}
                                    onClick={() => handleDownloadImage(image)}
                                />
                            </div>
                        </div>;
                    })
                }

            </Modal>
        </>
    );
};