import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Button, Card, Layout, message, Table, TableProps, Tooltip } from 'antd';
import { DeleteOutlined, EditOutlined, EyeOutlined, } from '@ant-design/icons';
import type { ColumnsType, SorterResult } from 'antd/es/table/interface';
import { IDocumentEntity, IItemsWithCount, ITransactionEntity, KeyValueRecord, transformToFormattedTime, PermissionsEnum, FilterOperatorsEnum } from '@rasayi-workspace/shared';
import { MapAntDesignSearchFilter, MapAntDesignSortOrder, RemoveUndefinedKeyPairs } from '@helpers';
import { BASE_QUERY_OPTIONS, DEFAULT_TABLE_STATE } from '@constants';
import { DeleteItem, EMPTY_INITIAL_ITEMS, GetTableItems, PatchItem, HasAnyPermission } from '@services';
import { BaseLayoutComponent, ColumnsSearchProps, DeleteConfirmationModal } from '@components';
import { ErrorResponse, ITableState } from '@interfaces';
import { EditDocumentModalComponent } from './edit';
const { Content } = Layout;

export const TransactionVerificationPageComponent = () => {
    const [messageApi, contextHolder] = message.useMessage();
    const [tableState, setTableState] = useState<ITableState>(DEFAULT_TABLE_STATE);
    const [transactionsData, setTransactionsData] = useState<IItemsWithCount<ITransactionEntity>>(EMPTY_INITIAL_ITEMS);
    const [isTransactionModalOpened, setIsTransactionModalOpened] = useState(false);
    const [editingTransactionId, setEditingTransactionId] = useState('');
    const [viewDocumentPaymentProofId, setViewDocumentPaymentProofId] = useState('');
    const [isViewDocumentModalOpened, setIsViewDocumentModalOpened] = useState(false);

    const [isEditPaidAmountModalVisible, setIsEditPaidAmountModalVisible] = useState<boolean>(false);
    const [currentTransaction, setCurrentTransaction] = useState<ITransactionEntity | null>(null);

    const { refetch, isFetching } = useQuery<IItemsWithCount<ITransactionEntity>, AxiosError>({
        ...BASE_QUERY_OPTIONS,
        queryKey: ['transaction_verification'],
        queryFn: () => GetTableItems<ITransactionEntity>(
            'transaction_verification',
            ['order', 'order.user', 'instalment', 'instalment.payment_type'],
            [],
            tableState,
            [
                [
                    {

                        field: 'verified',
                        operator: FilterOperatorsEnum.EQUAL,
                        value: 'false',

                    }
                ]
            ]
        ),
        onSuccess: (returnedResult: IItemsWithCount<ITransactionEntity>): void => setTransactionsData(returnedResult),
        onError: () => setTransactionsData(EMPTY_INITIAL_ITEMS)
    });

    const { mutate: deleteTransaction } = useMutation<ITransactionEntity, AxiosError, string>({
        mutationKey: ['deleteTransaction'],
        mutationFn: async (transactionId: string) => DeleteItem<ITransactionEntity>('transaction_verification', transactionId),
        onSuccess: async (): Promise<void> => {
            messageApi.open({
                type: 'success',
                content: `Transaction deleted!`,
            });

            refetch();
            resetModal();
        },
        onError: (error: AxiosError) => {
            messageApi.open({
                type: 'error',
                content: (error?.response?.data as ErrorResponse)?.message || 'Contact support for details',
            });
        },
    });

    const { mutate: updateTransactionAmount } = useMutation<
        ITransactionEntity,
        AxiosError,
        { id: string; amount: number }
    >({
        mutationKey: ['updateTransactionAmount'],
        mutationFn: async ({ id, amount }) => PatchItem('transaction', id, { amount }),
        onSuccess: async (): Promise<void> => {
            message.success('Transaction amount updated!');
            refetch();
            resetModal();
        },
        onError: (error: AxiosError) => {
            message.error(
                (error?.response?.data as ErrorResponse)?.message ||
                'Contact support for details'
            );
        },
    });

    const handleChange: TableProps<ITransactionEntity>['onChange'] = (pagination, filters, sorter) => {
        let sortBy: KeyValueRecord = RemoveUndefinedKeyPairs({
            [(sorter as SorterResult<ITransactionEntity>).columnKey as keyof ITransactionEntity]: MapAntDesignSortOrder((sorter as SorterResult<ITransactionEntity>).order)
        });

        sortBy = Object.keys(sortBy).length ? sortBy : DEFAULT_TABLE_STATE.sortBy;

        setTableState({
            ...tableState,
            page: pagination.current || tableState.page,
            pageSize: pagination.pageSize || tableState.pageSize,
            sortBy: sortBy,
            search: MapAntDesignSearchFilter(RemoveUndefinedKeyPairs(filters))
        });
    };

    const onTransactionModalCloseHandler = (newTransaction: ITransactionEntity) => {
        refetch();
        resetModal();

        messageApi.open(
            !editingTransactionId ? {
                type: 'success',
                content: `New transaction '${newTransaction?.id}' created!`,
            } : {
                type: 'success',
                content: `Transaction '${newTransaction?.id}' updated!`,
            }
        );
    }

    const onPaymentProofViewDocumentModalCloseHandler = (newPaymentProof: IDocumentEntity) => {
        refetch();
        resetModal();

        messageApi.open(
            !viewDocumentPaymentProofId ? {
                type: 'success',
                content: `New paymentProof '${newPaymentProof?.id}' created!`,
            } : {
                type: 'success',
                content: `PaymentProof '${newPaymentProof?.id}' updated!`,
            }
        );
    };

    const onModalCloseHandler = () => resetModal();

    const onViewDocumentModalCloseHandler = () => resetViewModal();

    const resetModal = () => {
        setIsTransactionModalOpened(false);
        setEditingTransactionId('');
        setIsEditPaidAmountModalVisible(false);
        setCurrentTransaction(null);
    }

    const resetViewModal = () => {
        setIsViewDocumentModalOpened(false);
        setViewDocumentPaymentProofId('');
    };

    const resetSearchHandler = (dataIndex: string) => {
        setTableState({
            ...tableState,
            search: tableState.search?.filter(item => !(item.dataKey === dataIndex)) || []
        });
    };

    const columns: ColumnsType<ITransactionEntity> = [
        {
            title: 'Order ID',
            dataIndex: ['order', 'internal_id'],
            key: 'order.internal_id',
            ellipsis: true,
            sorter: () => 0,
            sortDirections: ['ascend', 'descend'],
            ...ColumnsSearchProps('order.internal_id', 'by Order ID', resetSearchHandler)
        },
        {
            title: 'User CNIC',
            dataIndex: ['order', 'user', 'cnic_number'],
            key: 'order.user.cnic_number',
            ellipsis: true,
            sorter: () => 0,
            sortDirections: ['ascend', 'descend'],
            ...ColumnsSearchProps('order.user.cnic_number', 'by cnic_number', resetSearchHandler)
        },
        {
            title: 'Customer Name',
            dataIndex: ['order', 'user', 'first_name'],
            key: 'order.user.first_name',
            ellipsis: true,
            sorter: () => 0,
            sortDirections: ['ascend', 'descend'],
            render: (_: string, item: ITransactionEntity) => {
                return <Tooltip>
                    <span className='cursor-pointer'>
                        {
                            item?.order?.user?.first_name + ' ' + item?.order?.user?.last_name
                        }
                    </span>
                </Tooltip>;
            },
            ...ColumnsSearchProps('user.first_name', 'by First Name of User', resetSearchHandler)
        },
        {
            title: 'Total Amount',
            dataIndex: ['instalment', 'total_amount'],
            key: 'instalment.total_amount',
            ellipsis: true,
            sorter: () => 0,
            sortDirections: ['ascend', 'descend'],
            ...ColumnsSearchProps('instalment.total_amount', 'by total_amount', resetSearchHandler)
        },
        {
            title: 'Paid Amount',
            dataIndex: 'amount',
            key: 'amount',
            ellipsis: true,
            sorter: () => 0,
            sortDirections: ['ascend', 'descend'],
            render: (value: number, record: ITransactionEntity) => (
                <Tooltip title="Click to edit">
                    <Button type="link" onClick={() => {
                        setCurrentTransaction(record);
                        setIsEditPaidAmountModalVisible(true);
                    }}>
                        {value}
                    </Button>
                </Tooltip>
            ),
        },
        {
            title: 'Instalment Status',
            dataIndex: ['instalment', 'instalment_status'],
            key: 'instalment.instalment_status',
            ellipsis: true,
            sorter: () => 0,
            sortDirections: ['ascend', 'descend'],
            ...ColumnsSearchProps('instalment.instalment_status', 'by instalment_status', resetSearchHandler)
        },
        {
            title: 'Payment Type',
            dataIndex: ['instalment', 'payment_type', 'name'],
            key: 'instalment.payment_type.name',
            ellipsis: true,
            sorter: () => 0,
            render: (_: string, item: ITransactionEntity) => {
                const payment_type = item?.instalment?.payment_type?.name;

                return <Tooltip>
                    <span className='cursor-pointer'>
                        {
                            payment_type
                        }
                    </span>
                </Tooltip>;
            },
            ...ColumnsSearchProps('instalment.payment_type.name', 'by payment_type', resetSearchHandler)
        },
        {
            title: 'Payment Method',
            dataIndex: 'payment_method',
            key: 'payment_method',
            ellipsis: true,
            sorter: () => 0,
            sortDirections: ['ascend', 'descend'],
            ...ColumnsSearchProps('payment_method', 'by Procurement Method', resetSearchHandler)
        },
        {
            title: 'Verified',
            dataIndex: 'verified',
            key: 'verified',
            ellipsis: true,
            sorter: () => 0,
            sortDirections: ['ascend', 'descend'],
            render: (_: string, item: any) => {
                const is_verified = item?.verified.toString();

                return <Tooltip>
                    <span className='cursor-pointer'>
                        {
                            is_verified
                        }
                    </span>
                </Tooltip>;
            },
            ...ColumnsSearchProps('verified', 'by verified', resetSearchHandler)
        },
        {
            title: 'Instalment Paid',
            dataIndex: ['instalment', 'paid'],
            key: 'instalment.paid',
            ellipsis: true,
            sorter: () => 0,
            render: (_: string, item: ITransactionEntity) => {
                const paid = item?.instalment?.paid?.toString();

                return <Tooltip>
                    <span className='cursor-pointer'>
                        {
                            paid
                        }
                    </span>
                </Tooltip>;
            },
            ...ColumnsSearchProps('instalment.paid', 'by instalment paid', resetSearchHandler)
        },
        {
            title: 'Created at',
            dataIndex: 'created_at',
            key: 'created_at',
            ellipsis: true,
            render: (value: string) => transformToFormattedTime(value),
            sorter: () => 0,
            sortDirections: ['ascend', 'descend'],
        },
        {
            title: 'Last updated at',
            dataIndex: 'updated_at',
            key: 'updated_at',
            ellipsis: true,
            render: (value: string) => transformToFormattedTime(value),
            sorter: () => 0,
            sortDirections: ['ascend', 'descend'],
        },
        {
            title: 'Actions',
            dataIndex: 'operation',
            key: 'operation',
            render: (_, { id }) => (
                <div className="lg:flex">
                    <Button
                        type="text"
                        shape="circle"
                        icon={<EditOutlined />}
                        onClick={() => {
                            setViewDocumentPaymentProofId(id);
                            setIsViewDocumentModalOpened(true);
                        }}
                    />

                    <Button
                        className="ml-2"
                        type="text"
                        shape="circle"
                        icon={<DeleteOutlined />}
                        disabled={!HasAnyPermission([PermissionsEnum.USER_FULL, PermissionsEnum.USER_DELETE])}
                        onClick={() => {
                            DeleteConfirmationModal({
                                content: `Are you sure you want to delete Transaction ?`,
                                onOk: () => {
                                    deleteTransaction(id);
                                },
                                onCancel: () => resetModal(),
                            });
                        }}
                    />
                </div>
            ),
        },
    ];

    useEffect(() => {
        !isFetching && refetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tableState]);

    return (
        <>
            {contextHolder}
            {
                isViewDocumentModalOpened &&
                <EditDocumentModalComponent
                    editMode={!!viewDocumentPaymentProofId}
                    id={viewDocumentPaymentProofId}
                    onClose={onViewDocumentModalCloseHandler}
                    onSubmit={onPaymentProofViewDocumentModalCloseHandler}
                />
            }
            <BaseLayoutComponent children={
                <Content className='m-5 bg-white btransaction rounded-lg'>
                    <Card
                        title={'Transaction Verification'}
                    >
                        <div className='btransaction-2 btransaction-black rounded-lg'>
                            <Table
                                columns={columns}
                                dataSource={transactionsData.items}
                                onChange={handleChange}
                                pagination={
                                    {
                                        total: transactionsData.count,
                                        pageSize: transactionsData.pageSize,
                                        current: transactionsData.page,
                                    }
                                }
                                loading={isFetching}
                                rowKey={'id'}
                                size='small'
                                scroll={{ x: true }}
                            />
                        </div>
                    </Card>
                </Content>
            }
            />
        </>
    );
};