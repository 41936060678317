export enum DocumentTypeSubTypeEnum {
    USER = 'user',
    ORDER = 'order',
    PROCUREMENT = 'procurement',
    INSTALMENT = 'instalment',
    COMPLIANCE = 'compliance',
    TRANSACTION = 'transaction',
    OTHER = 'other',
    DELIVER = 'delivery',
    LEAD = 'lead'
}