import { Navigate } from 'react-router-dom';
import { IRouteConfiguration } from '@interfaces';
import {
    PermissionsEnum,
} from '@rasayi-workspace/shared';
import { SignedOutGuard, SignedInGuard, PageGuard } from '@guards';
import { GroupPageComponent, PermissionPageComponent, SignInPageComponent, UserPageComponent, RolePageComponent } from '@components';
import VendorPageComponent from '../components/pages/vendors';
import { LeadPageComponent } from '../components/pages/leads';
import { CustomerPageComponent } from '../components/pages/customers';
import { ProductTypePageComponent } from '../components/pages/product-types';
import BrandPageComponent from '../components/pages/brands';
import ChannelPageComponent from '../components/pages/channels';
import { OrderPageComponent } from '../components/pages/orders';
import { InstalmentPlanPageComponent } from '../components/pages/instalment-plan';
import InventoryPageComponent from '../components/pages/inventory';
import { ProcurementPageComponent } from '../components/pages/procurement';
import VendorBankDetailPageComponent from '../components/pages/vendor-bank-details';
import PaymentTypePageComponent from '../components/pages/payment-types';
import { InstalmentPageComponent } from '../components/pages/instalments';
import { TransactionPageComponent } from '../components/pages/transactions';
import MobileInfoPageComponent from '../components/pages/mobile-info';
import DeliveryPageComponent from '../components/pages/delivery';
import CompliancePageComponent from '../components/pages/compliance';
import { CreateOrderPageComponent } from '../components/pages/orders/create-order/customer-information';
import { UpdateOrderPageComponent } from '../components/pages/orders/update-order/order-information';
import { PayInstalmentPageComponent } from '../components/pages/instalments/pay-instalment/0-order-information';
import { CustomerInformation } from '../components/pages/delivery/create-delivery/1-customer-information';
import { GuarantorPageComponent } from '../components/pages/guarantor';
import BrandChargesPageComponent from '../components/pages/brand-charges';
import DashBoard from '../components/pages/dashboard';
import ActivityLogs from '../components/pages/activity-logs';
import { TransactionVerificationPageComponent } from '../components/pages/transaction-verfication';

/**
 * See guards/index.tsx whenever you change any route
 */
export const Routes: Record<string, IRouteConfiguration> = {
    random: {
        path: '*',
        element: <Navigate to={ `/sign-in` } replace/>
    },
    signin: {
        path: 'sign-in',
        element: <SignedOutGuard children={ <SignInPageComponent/> }/>
    },
    dashboard: {
        path: 'dashboard',
        element: <PageGuard
            // anyPermissions={[PermissionsEnum.USER_FULL, PermissionsEnum.USER_READ]}
            children={
                <SignedInGuard children={ <DashBoard/> }/>
            }
        />
    },
    users: {
        path: 'users',
        element: <PageGuard
            anyPermissions={ [PermissionsEnum.USER_FULL, PermissionsEnum.USER_READ] }
            children={
                <SignedInGuard children={ <UserPageComponent/> }/>
            }
        />
    },
    vendors: {
        path: 'vendors',
        element: <PageGuard
            // anyPermissions={[PermissionsEnum.USER_FULL, PermissionsEnum.USER_READ]}
            children={
                <SignedInGuard children={ <VendorPageComponent/> }/>
            }
        />
    },
    vendorBankDetails: {
        path: 'vendor-bank-details',
        element: <PageGuard
            // anyPermissions={[PermissionsEnum.USER_FULL, PermissionsEnum.USER_READ]}
            children={
                <SignedInGuard children={ <VendorBankDetailPageComponent/> }/>
            }
        />
    },
    leads: {
        path: 'leads',
        element: <PageGuard
            // anyPermissions={[PermissionsEnum.USER_FULL, PermissionsEnum.USER_READ]}
            children={
                <SignedInGuard children={ <LeadPageComponent/> }/>
            }
        />
    },
    orders: {
        path: 'orders',
        element: <PageGuard
            // anyPermissions={[PermissionsEnum.USER_FULL, PermissionsEnum.USER_READ]}
            children={
                <SignedInGuard children={ <OrderPageComponent/> }/>
            }
        />
    },
    createOrder: {
        path: 'create-order',
        element: <PageGuard
            // anyPermissions={[PermissionsEnum.USER_FULL, PermissionsEnum.USER_READ]}
            children={
                <SignedInGuard children={ <CreateOrderPageComponent/> }/>
            }
        />
    },
    updateOrder: {
        path: 'update-order',
        element: <PageGuard
            // anyPermissions={[PermissionsEnum.USER_FULL, PermissionsEnum.USER_READ]}
            children={
                <SignedInGuard children={ <UpdateOrderPageComponent/> }/>
            }
        />
    },
    instalments: {
        path: 'instalments',
        element: <PageGuard
            // anyPermissions={[PermissionsEnum.USER_FULL, PermissionsEnum.USER_READ]}
            children={
                <SignedInGuard children={ <InstalmentPageComponent/> }/>
            }
        />
    },
    payInstalment: {
        path: 'pay-instalment',
        element: <PageGuard
            // anyPermissions={[PermissionsEnum.USER_FULL, PermissionsEnum.USER_READ]}
            children={
                <SignedInGuard children={ <PayInstalmentPageComponent/> }/>
            }
        />
    },
    transactions: {
        path: 'transactions',
        element: <PageGuard
            // anyPermissions={[PermissionsEnum.USER_FULL, PermissionsEnum.USER_READ]}
            children={
                <SignedInGuard children={ <TransactionPageComponent/> }/>
            }
        />
    },
    transactionVerification: {
        path: 'transaction-verification',
        element: <PageGuard
            // anyPermissions={[PermissionsEnum.USER_FULL, PermissionsEnum.USER_READ]}
            children={
                <SignedInGuard children={ <TransactionVerificationPageComponent/> }/>
            }
        />
    },
    customers: {
        path: 'customers',
        element: <PageGuard
            // anyPermissions={[PermissionsEnum.USER_FULL, PermissionsEnum.USER_READ]}
            children={
                <SignedInGuard children={ <CustomerPageComponent/> }/>
            }
        />
    },
    guarantors: {
        path: 'guarantors',
        element: <PageGuard
            // anyPermissions={[PermissionsEnum.USER_FULL, PermissionsEnum.USER_READ]}
            children={
                <SignedInGuard children={<GuarantorPageComponent />} />
            }
        />
    },
    productTypes: {
        path: 'product-types',
        element: <PageGuard
            // anyPermissions={[PermissionsEnum.USER_FULL, PermissionsEnum.USER_READ]}
            children={
                <SignedInGuard children={ <ProductTypePageComponent/> }/>
            }
        />
    },
    paymentTypes: {
        path: 'payment-types',
        element: <PageGuard
            // anyPermissions={[PermissionsEnum.USER_FULL, PermissionsEnum.USER_READ]}
            children={
                <SignedInGuard children={ <PaymentTypePageComponent/> }/>
            }
        />
    },
    instalmentPlans: {
        path: 'instalment-plans',
        element: <PageGuard
            // anyPermissions={[PermissionsEnum.USER_FULL, PermissionsEnum.USER_READ]}
            children={
                <SignedInGuard children={ <InstalmentPlanPageComponent/> }/>
            }
        />
    },
    brands: {
        path: 'brands',
        element: <PageGuard
            // anyPermissions={[PermissionsEnum.USER_FULL, PermissionsEnum.USER_READ]}
            children={
                <SignedInGuard children={ <BrandPageComponent/> }/>
            }
        />
    },
    brandCharges: {
        path: 'brand-charges',
        element: <PageGuard
            // anyPermissions={[PermissionsEnum.USER_FULL, PermissionsEnum.USER_READ]}
            children={
                <SignedInGuard children={ <BrandChargesPageComponent/> }/>
            }
        />
    },
    channels: {
        path: 'channels',
        element: <PageGuard
            // anyPermissions={[PermissionsEnum.USER_FULL, PermissionsEnum.USER_READ]}
            children={
                <SignedInGuard children={ <ChannelPageComponent/> }/>
            }
        />
    },
    inventory: {
        path: 'inventory',
        element: <PageGuard
            // anyPermissions={[PermissionsEnum.USER_FULL, PermissionsEnum.USER_READ]}
            children={
                <SignedInGuard children={ <InventoryPageComponent/> }/>
            }
        />
    },
    delivery: {
        path: 'delivery',
        element: <PageGuard
            // anyPermissions={[PermissionsEnum.USER_FULL, PermissionsEnum.USER_READ]}
            children={
                <SignedInGuard children={ <DeliveryPageComponent/> }/>
            }
        />
    },
    readyToDeliver: {
        path: 'create-delivery',
        element: <PageGuard
            // anyPermissions={[PermissionsEnum.USER_FULL, PermissionsEnum.USER_READ]}
            children={
                <SignedInGuard children={ <CustomerInformation/> }/>
            }
        />
    },
    compliance: {
        path: 'compliance',
        element: <PageGuard
            // anyPermissions={[PermissionsEnum.USER_FULL, PermissionsEnum.USER_READ]}
            children={
                <SignedInGuard children={ <CompliancePageComponent/> }/>
            }
        />
    },
    mobileInfo: {
        path: 'mobile-info',
        element: <PageGuard
            // anyPermissions={[PermissionsEnum.USER_FULL, PermissionsEnum.USER_READ]}
            children={
                <SignedInGuard children={ <MobileInfoPageComponent/> }/>
            }
        />
    },
    procurement: {
        path: 'procurement',
        element: <PageGuard
            // anyPermissions={[PermissionsEnum.USER_FULL, PermissionsEnum.USER_READ]}
            children={
                <SignedInGuard children={ <ProcurementPageComponent/> }/>
            }
        />
    },
    roles: {
        path: 'roles',
        element: <PageGuard
            anyPermissions={ [PermissionsEnum.ROLE_FULL, PermissionsEnum.ROLE_READ] }
            children={
                <SignedInGuard children={ <RolePageComponent/> }/>
            }
        />
    },
    groups: {
        path: 'groups',
        element: <PageGuard
            anyPermissions={ [PermissionsEnum.GROUP_FULL, PermissionsEnum.GROUP_READ] }
            children={
                <SignedInGuard children={ <GroupPageComponent/> }/>
            }
        />
    },
    activityLogs: {
        path: 'activity-logs',
        element: <PageGuard
            anyPermissions={ [PermissionsEnum.ACTIVITY_LOGS] }
            children={
                <SignedInGuard children={ <ActivityLogs/> }/>
            }
        />
    },
    permissions: {
        path: 'permissions',
        element: <PageGuard
            anyPermissions={ [PermissionsEnum.PERMISSION_FULL, PermissionsEnum.PERMISSION_READ] }
            children={
                <SignedInGuard children={ <PermissionPageComponent/> }/>
            }
        />
    }
};