import { Option } from '@types';
import { ITableState } from '@interfaces';
import { KeyValueRecord } from '@rasayi-workspace/shared';

export const DEBOUNCE_TIMER = 500;
export const PER_PAGE_OPTIONS = [5, 10, 20, 30, 50, 100];
export const DEFAULT_TABLE_STATE: ITableState = {
    page: 1,
    pageSize: PER_PAGE_OPTIONS[1],
    search: [],
    sortBy: { 'created_at': 'DESC' }
};
export const EMPTY_OPTION: Option = { key: '', value: '' };
export const BASE_QUERY_OPTIONS = {
    enabled: false,
    retry: false,
    cacheTime: 5000
}, MAX_FILE_SIZE_MB = 10;
export * from './provinces-and-cities';

export const isFileSizeValid = (file: File, fileSize?: number): boolean => {
    const fileSizeMB = file.size / (1024 * 1024);
    return fileSizeMB <= (fileSize ? fileSize : MAX_FILE_SIZE_MB);
};

export const enumColumns = ['customer_status', 'quantity', 'segment', 'status', 'lock_status', 'order_status',
    'contract_status', 'contract_type', 'downpayment', 'total_retail_price', 'total_customer_price', 'product_code', 'index', 'days', 'cycle',
    'approved', 'is_verified', 'total_purchase_price', 'retail_price', 'suggested_retail_price_per_piece', 'final_customer_Price', 'charges', 'total_amount', 'amount', 'instalment_status',
    'paid', 'paid_amount', 'signature', 'procurement_type', 'procurement_status', 'lead_status', 'thumb', 'box_collection', 'downpayment_collection',
    'payment_method', 'compliance_status', 'delivery_status', 'delivered_by'
];

export const siderStyle: KeyValueRecord = {
    overflow: 'auto',
    height: '85vh',
    padddingBottom: 200,
    scrollbarWidth: 'thin',
    scrollbarColor: 'unset',
};

export const optionsIsVerified = [
    {
        value: true,
        label: 'Yes',
    },
    {
        value: false,
        label: 'No'
    }
];