import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Button, Card, Layout, message, Table, TableProps } from 'antd';
import { CloudUploadOutlined, DeleteOutlined, EditOutlined, EyeOutlined, } from '@ant-design/icons';
import type { ColumnsType, SorterResult } from 'antd/es/table/interface';
import { FilterOperatorsEnum, IDocumentEntity, IItemsWithCount, IUserEntity, KeyValueRecord, PermissionsEnum, transformToFormattedTime, UserRolesEnum } from '@rasayi-workspace/shared';
import { MapAntDesignSearchFilter, MapAntDesignSortOrder, RemoveUndefinedKeyPairs } from '@helpers';
import { BASE_QUERY_OPTIONS, DEFAULT_TABLE_STATE } from '@constants';
import { DeleteItem, EMPTY_INITIAL_ITEMS, GetTableItems, HasAnyPermission } from '@services';
import { BaseLayoutComponent, ColumnsSearchProps, DeleteConfirmationModal, TablePageTitleComponent } from '@components';
import { ITableState } from '@interfaces';
import { UserModalComponent } from './add-edit';
import { AddDocumentModalComponent } from './add-document';
import { ViewDocumentModalComponent } from './view-documents';
import { CompanyUserModalComponent } from './add-edit-company';

const { Content } = Layout;

export const CustomerPageComponent = () => {
    const [messageApi, contextHolder] = message.useMessage();
    const [tableState, setTableState] = useState<ITableState>(DEFAULT_TABLE_STATE);
    const [usersData, setUsersData] = useState<IItemsWithCount<IUserEntity>>(EMPTY_INITIAL_ITEMS);
    const [isUserModalOpened, setIsUserModalOpened] = useState(false);
    const [isAddDocumentModalOpened, setIsAddDocumentModalOpened] = useState(false);
    const [isViewDocumentModalOpened, setIsViewDocumentModalOpened] = useState(false);
    const [editingUserId, setEditingUserId] = useState('');
    const [editingDocumentUserId, setEditingDocumentUserId] = useState('');
    const [viewDocumentUserId, setViewDocumentUserId] = useState('');
    const [isCompanyCustomerModalOpened, setIsCompanyCustomerModalOpened] = useState(false);

    const { refetch, isFetching: isUserFetching, } = useQuery<IItemsWithCount<IUserEntity>, AxiosError>({
        ...BASE_QUERY_OPTIONS,
        queryKey: ['users'],
        queryFn: () => GetTableItems<IUserEntity>(
            'user',
            ['roles', 'groups'],
            [],
            tableState,
            [
                [
                    {
                        field: 'roles.name',
                        operator: FilterOperatorsEnum.EQUAL,
                        value: UserRolesEnum.CUSTOMER
                    }
                ]
            ]
        ),
        onSuccess: (returnedResult: IItemsWithCount<IUserEntity>): void => setUsersData(returnedResult),
        onError: (err) => setUsersData(EMPTY_INITIAL_ITEMS)
    });

    const { mutate: deleteUser } = useMutation<IUserEntity, AxiosError>({
        mutationKey: ['deleteUser'],
        mutationFn: async () =>
            DeleteItem<IUserEntity>(
                'user',
                editingUserId,
            ),
        onSuccess: async (): Promise<void> => {
            messageApi.open({
                type: 'success',
                content: `User deleted!`,
            });
            !isUserFetching && refetch();
            resetModal();
        },
        onError: (error: AxiosError) => {
            messageApi.open({
                type: 'error',
                content: (error as KeyValueRecord).response?.data?.message.join(', ') || 'Contact support for details'
            });
        }
    });

    const handleChange: TableProps<IUserEntity>['onChange'] = (pagination, filters, sorter) => {
        let sortBy: KeyValueRecord = RemoveUndefinedKeyPairs({
            [(sorter as SorterResult<IUserEntity>).columnKey as keyof IUserEntity]: MapAntDesignSortOrder((sorter as SorterResult<IUserEntity>).order)
        });

        sortBy = Object.keys(sortBy).length ? sortBy : DEFAULT_TABLE_STATE.sortBy;

        setTableState({
            ...tableState,
            page: pagination.current || tableState.page,
            pageSize: pagination.pageSize || tableState.pageSize,
            sortBy: sortBy,
            search: MapAntDesignSearchFilter(RemoveUndefinedKeyPairs(filters))
        });
    };

    const onUserModalCloseHandler = (newUser: IUserEntity) => {
        !isUserFetching && refetch();
        resetModal();

        messageApi.open(
            !editingUserId ? {
                type: 'success',
                content: `New user '${newUser?.first_name}' created!`,
            } : {
                type: 'success',
                content: `User '${newUser?.first_name}' updated!`,
            }
        );
    };

    const onUserAddDocumentModalCloseHandler = (newUser: IDocumentEntity) => {
        !isUserFetching && refetch();
        resetModal();

        messageApi.open(
            !editingDocumentUserId ? {
                type: 'success',
                content: `New user '${newUser?.id + ' ' + newUser?.name}' created!`,
            } : {
                type: 'success',
                content: `User '${newUser?.id + ' ' + newUser?.name}' updated!`,
            }
        );
    };

    const onUserViewDocumentModalCloseHandler = (newUser: IDocumentEntity) => {
        !isUserFetching && refetch();
        resetModal();

        messageApi.open(
            !viewDocumentUserId ? {
                type: 'success',
                content: `New user '${newUser?.id + ' ' + newUser?.name}' created!`,
            } : {
                type: 'success',
                content: `User '${newUser?.id + ' ' + newUser?.name}' updated!`,
            }
        );
    };

    const onCompanyCustomerSubmitHandler = (companyCustomerData: IUserEntity) => {
        !isUserFetching && refetch();
        setIsCompanyCustomerModalOpened(false);
        messageApi.open({
            type: 'success',
            content: `Company customer '${companyCustomerData.ntn_number}' created!`,
        });
    };

    const onModalCloseHandler = () => resetModal();

    const onAddDocumentModalCloseHandler = () => resetAddModal();

    const onViewDocumentModalCloseHandler = () => resetViewModal();

    const resetModal = () => {
        setIsUserModalOpened(false);
        setEditingUserId('');
    };

    const resetAddModal = () => {
        setIsAddDocumentModalOpened(false);
        setEditingDocumentUserId('');
    };

    const resetViewModal = () => {
        setIsViewDocumentModalOpened(false);
        setViewDocumentUserId('');
    };

    const onCompanyCustomerModalCloseHandler = () => {
        setIsCompanyCustomerModalOpened(false);
    };

    const resetSearchHandler = (dataIndex: string) => {
        setTableState({
            ...tableState,
            search: tableState.search?.filter(item => !(item.dataKey === dataIndex)) || []
        });
    };

    const columns: ColumnsType<IUserEntity> = [
        {
            title: 'First name',
            dataIndex: 'first_name',
            key: 'first_name',
            ellipsis: true,
            sorter: () => 0,
            sortDirections: ['ascend', 'descend'],
            ...ColumnsSearchProps('first_name', 'by first name', resetSearchHandler)
        },
        {
            title: 'Last name',
            dataIndex: 'last_name',
            key: 'last_name',
            ellipsis: true,
            sorter: () => 0,
            sortDirections: ['ascend', 'descend'],
            ...ColumnsSearchProps('last_name', 'by last name', resetSearchHandler)
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            ellipsis: true,
            sorter: () => 0,
            sortDirections: ['ascend', 'descend'],
            ...ColumnsSearchProps('email', 'by email', resetSearchHandler)
        },
        {
            title: 'Cnic number',
            dataIndex: 'cnic_number',
            key: 'cnic_number',
            ellipsis: true,
            sorter: () => 0,
            sortDirections: ['ascend', 'descend'],
            ...ColumnsSearchProps('cnic_number', 'by cnic_number', resetSearchHandler)
        },
        {
            title: 'Primary mobile',
            dataIndex: 'primary_mobile',
            key: 'primary_mobile',
            ellipsis: true,
            sorter: () => 0,
            sortDirections: ['ascend', 'descend'],
            ...ColumnsSearchProps('primary_mobile', 'by primary_mobile', resetSearchHandler)
        },
        {
            title: 'City',
            dataIndex: 'city',
            key: 'city',
            ellipsis: true,
            sorter: () => 0,
            sortDirections: ['ascend', 'descend'],
            ...ColumnsSearchProps('city', 'by city', resetSearchHandler)
        },
        {
            title: 'Customer Status',
            dataIndex: 'customer_status',
            key: 'customer_status',
            ellipsis: true,
            sorter: () => 0,
            sortDirections: ['ascend', 'descend'],
            ...ColumnsSearchProps('customer_status', 'by customer_status', resetSearchHandler)
        },
        {
            title: 'Created at',
            dataIndex: 'created_at',
            key: 'created_at',
            ellipsis: true,
            render: (value: string) => transformToFormattedTime(value),
            sorter: () => 0,
            sortDirections: ['ascend', 'descend'],
        },
        {
            title: 'Last updated at',
            dataIndex: 'updated_at',
            key: 'updated_at',
            ellipsis: true,
            render: (value: string) => transformToFormattedTime(value),
            sorter: () => 0,
            sortDirections: ['ascend', 'descend'],
        },
        {
            title: 'View documents',
            dataIndex: 'operation',
            key: 'operation',
            render: (_, { id, first_name = '', last_name = '' }) => (
                <div className='lg:flex'>
                    <Button
                        type='text'
                        shape='circle'
                        icon={<EyeOutlined />}
                        disabled={!HasAnyPermission([PermissionsEnum.USER_FULL, PermissionsEnum.USER_WRITE])}
                        onClick={
                            () => {
                                setViewDocumentUserId(id);
                                setIsViewDocumentModalOpened(true);
                            }
                        }
                    />
                </div>
            ),
        },
        {
            title: 'Actions',
            dataIndex: 'operation',
            key: 'operation1',
            render: (_, { id, first_name = '', last_name = '' }) => (
                <div className='lg:flex'>
                    <Button
                        type='text'
                        shape='circle'
                        icon={<CloudUploadOutlined />}
                        disabled={!HasAnyPermission([PermissionsEnum.USER_FULL, PermissionsEnum.USER_WRITE])}
                        onClick={
                            () => {
                                setEditingDocumentUserId(id);
                                setIsAddDocumentModalOpened(true);
                            }
                        }
                    />
                    <Button
                        type='text'
                        shape='circle'
                        icon={<EditOutlined />}
                        disabled={!HasAnyPermission([PermissionsEnum.USER_FULL, PermissionsEnum.USER_WRITE])}
                        onClick={
                            () => {
                                setEditingUserId(id);
                                setIsUserModalOpened(true);
                            }
                        }
                    />
                    <Button
                        className='ml-2'
                        type='text'
                        shape='circle'
                        icon={<DeleteOutlined />}
                        disabled={!HasAnyPermission([PermissionsEnum.USER_FULL, PermissionsEnum.USER_DELETE])}
                        onClick={
                            () => {
                                setEditingUserId(id);

                                DeleteConfirmationModal({
                                    content: `Are you sure you want to delete user '${first_name}'?`,
                                    onOk: () => {
                                        deleteUser();
                                    },
                                    onCancel: () => resetModal()

                                });
                            }
                        }
                    />
                </div>
            ),
        }
    ];

    useEffect(() => {
        !isUserFetching && refetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tableState]);

    return (
        <>
            {contextHolder}
            {
                isCompanyCustomerModalOpened &&
                <CompanyUserModalComponent
                    onClose={onCompanyCustomerModalCloseHandler}
                    onSubmit={onCompanyCustomerSubmitHandler} // Handle form submission for company customer
                />
            }
            {
                isUserModalOpened &&
                <UserModalComponent
                    editMode={!!editingUserId}
                    id={editingUserId}
                    onClose={onModalCloseHandler}
                    onSubmit={onUserModalCloseHandler}
                />
            }
            {
                isAddDocumentModalOpened &&
                <AddDocumentModalComponent
                    editMode={!!editingDocumentUserId}
                    id={editingDocumentUserId}
                    onClose={onAddDocumentModalCloseHandler}
                    onSubmit={onUserAddDocumentModalCloseHandler}
                />
            }
            {
                isViewDocumentModalOpened &&
                <ViewDocumentModalComponent
                    editMode={!!viewDocumentUserId}
                    id={viewDocumentUserId}
                    onClose={onViewDocumentModalCloseHandler}
                    onSubmit={onUserViewDocumentModalCloseHandler}
                />
            }
            <BaseLayoutComponent children={
                <Content className='m-5 bg-white border rounded-lg'>
                    <Card title='customer'
                        extra={
                            <div className='flex justify-between gap-4'>
                                <Button
                                    type="primary"
                                    disabled={!HasAnyPermission([PermissionsEnum.USER_FULL, PermissionsEnum.USER_WRITE])}
                                    onClick={() => setIsCompanyCustomerModalOpened(true)} // Open company customer modal
                                >
                                    Add Company Customer
                                </Button>

                                <Button
                                    type="primary"
                                    disabled={!HasAnyPermission([PermissionsEnum.USER_FULL, PermissionsEnum.USER_WRITE])}
                                    onClick={() => setIsUserModalOpened(true)}
                                >
                                    Add Customer
                                </Button>
                            </div>
                        }
                    >
                        <div className='border-2 border-black rounded-lg'>
                            <Table
                                columns={columns}
                                scroll={{ x: true }}
                                dataSource={usersData.items}
                                onChange={handleChange}
                                pagination={
                                    {
                                        total: usersData.count,
                                        pageSize: usersData.pageSize,
                                        current: usersData.page,
                                    }
                                }
                                loading={isUserFetching}
                                rowKey={'id'}
                                size='small'
                            />
                        </div>
                    </Card>
                </Content>
            }
            />
        </>
    );
};