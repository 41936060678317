import { useEffect, useMemo, useState } from 'react';
import { ConfigProvider, DatePicker, Form, Input, message, Modal, Select, Tag } from 'antd';
import { useMutation, useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { CustomerStatusEnumerator, GenderEnumeratorFrontend, getEnumeratorKeys, IUserDTO, IUserEntity, ProvinceEnumerator, regexCollection, removeEmptyValues, removeWhiteSpaceFromAllProperties, UserTypeEnum } from '@rasayi-workspace/shared';
import { GetItem, PostItem, PutItem } from '@services';
import { BASE_QUERY_OPTIONS, PROVINCES_AND_CITIES, optionsIsVerified } from '@constants';
import { AddEditModalProps, ErrorResponse } from '@interfaces';
import dayjs from 'dayjs';
import locale from 'antd/locale/en_US';

export const CompanyUserModalComponent = ({ onClose, onSubmit, editMode = false, id: userId = '' }: AddEditModalProps<IUserEntity>) => {
    const [form] = Form.useForm<Partial<IUserDTO>>();
    const [messageApi, contextHolder] = message.useMessage();
    const [isValidForm, setIsValidForm] = useState(false);
    const [user, setUser] = useState<Partial<IUserEntity>>();
    const [selectedDateOfBirth, setSelectedDateOfBirth] = useState<dayjs.Dayjs | null>(null);

    const { mutate: createUser, isLoading } = useMutation<IUserEntity, AxiosError>({
        mutationKey: ['createUser'],
        mutationFn: async () =>
            PostItem<IUserEntity, IUserDTO>(
                'user/addCustomer',
                removeWhiteSpaceFromAllProperties(removeEmptyValues({ ...form.getFieldsValue(), user_type: UserTypeEnum.B2B })) as Partial<IUserDTO>
            ),
        onSuccess: async (item: IUserEntity): Promise<void> => onSubmit(item),
        onError: (error: AxiosError) => {
            messageApi.open({
                type: 'error',
                content: (error?.response?.data as ErrorResponse)?.message || 'Contact support for details'
            });
        }
    });

    const { mutate: updateUser, isLoading: isUserUpdating } = useMutation<IUserEntity, AxiosError>({
        mutationKey: ['updateUser'],
        mutationFn: async () =>
            PutItem<IUserEntity, IUserDTO>(
                'user',
                userId,
                removeWhiteSpaceFromAllProperties(removeEmptyValues(form.getFieldsValue())) as Partial<IUserDTO>
            ),
        onSuccess: async (item: IUserEntity): Promise<void> => onSubmit(item),
        onError: (error: AxiosError) => {
            messageApi.open({
                type: 'error',
                content: (error?.response?.data as ErrorResponse)?.message || 'Contact support for details'
            });
        }
    });

    const { refetch: fetchUser, isFetching: isUserFetching } = useQuery<IUserEntity, AxiosError>({
        ...BASE_QUERY_OPTIONS,
        queryKey: ['getUser'],
        queryFn: () => GetItem<IUserEntity>(
            'user',
            userId || '',
            ['roles', 'groups'],
            ['roles.id', 'roles.name', 'groups.id', 'groups.name']
        ),
        onSuccess: (result: IUserEntity): void => {
            setUser(result);
            form.setFieldsValue({
                ...result,
                role_ids: result.roles?.map(({ id }) => id)
            });
        },
        onError: (error) => messageApi.open({
            type: 'error',
            content: (error?.response?.data as ErrorResponse)?.message || 'Contact support for details'
        })
    });

    const updateFormFields = (property: string, value: string) => form.setFieldsValue({ [property]: value });

    const handleFormChange = () => {
        const fieldsTouched = form.isFieldsTouched(true);
        const hasErrors = form.getFieldsError().filter(({ errors }) => errors.length)
            .length > 0;

        setIsValidForm(fieldsTouched && !hasErrors);
    };

    const onOkAddButtonClickHandler = async () => await form.validateFields()
        .then(() => createUser())
        .catch(() => setIsValidForm(false));

    const onOkEditButtonClickHandler = async () => await form.validateFields()
        .then(() => updateUser())
        .catch(() => setIsValidForm(false));

    useEffect(() => {
        if (editMode) {
            fetchUser();
            setSelectedDateOfBirth(dayjs(user?.dob));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editMode, user?.dob]);

    const cities = useMemo(
        () => Object
            .values(PROVINCES_AND_CITIES)
            .reduce(
                (aggregator, values) => ([...aggregator, ...values]), []
            )
            .sort(),
        []
    );

    return (
        <>
            {contextHolder}
            <Modal
                title={!editMode ? 'Add Customer' : 'Edit Customer'}
                open={true}
                confirmLoading={isLoading || isUserFetching || isUserUpdating}
                // okButtonProps={{ disabled: !isValidForm }}
                okText={!editMode ? 'Add Customer' : 'Save'}
                onOk={!editMode ? onOkAddButtonClickHandler : onOkEditButtonClickHandler}
                onCancel={() => onClose()}
                maskClosable={false}
                bodyStyle={{ maxHeight: 550, overflow: 'auto' }}
            >
                <Form
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    form={form}
                    onValuesChange={handleFormChange}
                >
                    <Form.Item
                        name='first_name'
                        label='First Name'
                        rules={[
                            { required: true, message: 'Please enter the first name' },
                            { min: 2, message: 'Atleast 2 characters' }
                        ]}
                    >
                        <Input onChange={(event) => updateFormFields('first_name', event?.target.value)} />
                    </Form.Item>
                    <Form.Item
                        name='last_name'
                        label='Last Name'
                        rules={[
                            // { required: true, message: 'Please enter the last name' },
                            { min: 2, message: 'Atleast 2 characters' }
                        ]}
                    >
                        <Input onChange={(event) => updateFormFields('last_name', event?.target.value)} />
                    </Form.Item>

                    <Form.Item
                        name='cnic_number'
                        label='Cnic number'
                        rules={[
                            {
                                required: true, message: 'Please enter correct cnic number like xxxxx-xxxxxxx-x',
                                pattern: regexCollection.cnic
                            }
                        ]}
                    >
                        <Input
                            // type='number'
                            className='[appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none'
                            maxLength={15} minLength={15}
                            onChange={(event) => updateFormFields('cnic_number', event?.target.value)} />
                    </Form.Item>
                    {/* <Form.Item
                        name='primary_mobile'
                        label='Primary mobile'
                        rules={ [
                            {
                                required: true, message: 'Please enter correct mobile like 03xxxxxxxxx',
                                pattern: regexCollection.phone
                            }
                        ] }
                    >
                        <Input
                            maxLength={ 11 }
                            minLength={ 11 }
                            onChange={ (event) => updateFormFields('primary_mobile', event?.target.value) }
                        />
                    </Form.Item> */}

                    <Form.Item
                        name='designation'
                        label='Designation'
                        rules={[
                            { required: true, message: 'Please enter the designation' }
                        ]}
                    >
                        <Input
                            onChange={(event) => updateFormFields('Designation', event?.target.value)} />
                    </Form.Item>
                    <Form.Item
                        name='ntn_number'
                        label='NTN Number'
                        rules={[
                            { required: true, message: 'Please enter the NTN Number' }
                        ]}
                    >
                        <Input
                            onChange={(event) => updateFormFields('ntn_number', event?.target.value)} />
                    </Form.Item>
                    <Form.Item
                        name='address_line1'
                        label='Office Address'
                        rules={[
                            { required: true, message: 'Please enter the Office Address' }
                        ]}
                    >
                        <Input
                            onChange={(event) => updateFormFields('address_line1', event?.target.value)} />
                    </Form.Item>
                    <Form.Item
                        name='city'
                        label='City'
                        rules={[
                            { required: true, message: 'Please enter the city' }
                        ]}
                    >
                        <Select
                            showSearch
                            optionFilterProp='children'
                            filterOption={(input, option: any) =>
                                option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {cities.map((o, i) => (
                                <Select.Option key={`option-${i}`} value={o}>
                                    {o}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name='province'
                        label='Province'
                        rules={[
                            { required: true, message: 'Please enter the province' }
                        ]}
                    >
                        <Select onChange={(value) => updateFormFields('province', value)}>
                            {
                                getEnumeratorKeys(ProvinceEnumerator).map(
                                    (key) => <Select.Option key={key}
                                        value={ProvinceEnumerator[key as keyof typeof ProvinceEnumerator]}>
                                        <Tag key={key}>{key.toUpperCase()}</Tag>
                                    </Select.Option>
                                )
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name='postal_code'
                        label='Postal code'
                        rules={[
                            { required: false, message: 'Please enter the postal code' }
                        ]}
                    >
                        <Input
                            maxLength={5}
                            onChange={(event) => updateFormFields('postal_code', event?.target.value)} />
                    </Form.Item>
                    <Form.Item
                        name='is_active'
                        label='Is Verified'
                        rules={[{ required: true, message: 'Select to enable or disable the customer.' }]}
                        initialValue={false}
                    >
                        <Select>
                            {optionsIsVerified.map((o, i) => {
                                return (
                                    <Select.Option key={`option-${i}`} value={o.value}>
                                        {o.label}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};